button {
  font-family: 'Cuprum', sans-serif;
  text-transform: uppercase;
};

p {
  font-family: 'Cuprum', sans-serif
}

.plus {
  --b:4px; /* the thickness */
  width:40px; /* the size */
  aspect-ratio:1/1;
  border:10px solid #000; /* the outer space */
  display:inline-block;
}

.alt {
  border-color:#fff;
}